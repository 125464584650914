import React from 'react';

import ReactPlayer from 'react-player';

interface MyProps {

}

interface MyState {
    buttonActivated: boolean
}

export class RedImageButton extends React.Component<MyProps, MyState> {
    constructor(props: any) {
        super(props);
        this.state = {buttonActivated: false};
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.setState(state => ({
            buttonActivated: !state.buttonActivated
        }));
    }

    render() {
        // let color: string = this.state.buttonActivated ? "w3-red" : "w3-green";

        // if (!this.state.buttonActivated) {
        // }

        //className="w3-button w3-red w3-round-xlarge"
        //className={`w3-button ${color} w3-round-xlarge`}

        let videoPlaying = this.state.buttonActivated ? (
            <span>
                <br />
                <br />
                <ReactPlayer
                    url="https://www.youtube.com/watch?v=WnH0Kvl3thk?t=3"
                    playing={true}
                />
            </span>
        ) : null;
        return (
            <span>
                <button
                    className="w3-button w3-red w3-round-xlarge"
                    onClick={this.handleClick}>
                        CLICK HERE TO SEE DESU'S PENIS
                </button>
                {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/WnH0Kvl3thk" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}


                {/* <ReactPlayer
                    url="https://www.youtube.com/watch?v=WnH0Kvl3thk?t=3"
                    playing={true}
                /> */}
                {videoPlaying}
            </span>
        );
    }
  }