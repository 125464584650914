import React from 'react';
import logo from './logo.svg';
import './App.css';

import { HeaderComponent } from './components/headerbar';
// import { MainComponent } from './components/main';
import { RedImageButton } from './components/redImageButton'

import { ImagePostComponent } from './components/imagePost';

//#000!important -- black
//#282c34 -- original background color
//#5f5f5f -- a softer gray

function App() {
    return (
        <div className="App">
            {/* <header className="App-header">
              <img src={logo} className="App-logo" alt="logo" />
              <p>
                Editttttt <code>src/App.js</code> and save to reload.
              </p>
              <a
                className="App-link"
                href="https://reactjs.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn React
              </a>
            </header> */}
            <HeaderComponent />

            <div className="custom-app-body" style={{ marginTop: 46 }}>
                <br/>
                <br/>
                <br/>
                A GLORIOUS TRIBUTE TO DESU'S HOLY CRUSADE AGAINST CAPITAL ONE

                <br/>
                <br/>
                <br/>
                Actual picture of Desu:
                {/* https://i.imgur.com/exgWyIB.jpg */}
                {/* <img src="https://i.imgur.com/exgWyIB.jpg" style="width:100%; border-radius: 6px;"></img> */}
                <img src="https://i.imgur.com/9zxaXBf.jpg" alt="desu" style={{maxWidth: '100%'}}/>
                <br/>
                <br/>

                <br/>
                <br/>
                <br/>
            </div>
            <div className="custom-app-body-2">
                <br/>
                I never keep this shit up to date...
                <br/>
                <br/>
                Desu's current ban status on Blind:&nbsp;
                <span style={{color: 'green'}}>NOT BANNED</span>
                {/* <span><span style={{color: 'red'}}>BANNED</span> <span className="blinking">for partying too hard</span></span> */}



                {/* <br/>
                <span>The one month ban expires Oct. 24th/25th</span>

                <br/>
                <span>Meanwhile, quick shout out to @aware_wolf for <a href="https://www.teamblind.com/article/Vote-Best-Capital-One-Meme-iUheFTKR">their lit thread on blind</a>.  You make my <a href="https://www.youtube.com/watch?v=HmAsUQEFYGI">earf quake</a>.</span> */}

                {/* <hr /> */}



                <br />

                "Fanmail" address:
                <img src="https://i.imgur.com/X7HxjYW.png" alt="fanmail"/>
                <br/>
                {/* <button class="w3-button w3-red w3-round-xlarge">CLICK HERE TO SEE DESU'S PENIS</button> */}
                <RedImageButton />
                <br/>
                Events:
                <br />
                Desu Fucks DC - Tentatively scheduled April 20th, 2021
                <br/>
                <br/>
            </div>


            <div className="custom-app-body-3">
                <br/>
                <br/>
                <br/>
                DEGENERATE MEMES

                {/* meme 37 */}
                {/* meme 38 */}
                {/* meme 39 https://i.imgur.com/nDPti6G.jpg */}
                {/* meme 40 */}

                <br/>
                <br/>
                <a id="post31" href="http://desudesudesu.io/#post31">Post 31</a>
                <img src="https://i.imgur.com/ymviOKl.jpg" alt="the great default" style={{maxWidth: '100%'}}/>

                <br/>
                <br/>
                <a id="post30" href="http://desudesudesu.io/#post30">Post 30</a>
                <img src="https://i.imgur.com/LU5dkCb.jpg" alt="so true" style={{maxWidth: '100%'}}/>

                <br/>
                <br/>
                <a id="post29" href="http://desudesudesu.io/#post29">Post 29</a>
                <img src="https://i.imgur.com/qEl7jUQ.jpg" alt="ending yourself" style={{maxWidth: '100%'}}/>

                <br/>
                <br/>
                <a id="post28" href="http://desudesudesu.io/#post28">Post 28</a>
                <img src="https://i.imgur.com/i1MCN7i.jpg" alt="subtle racism" style={{maxWidth: '100%'}}/>








                <br/>
                <br/>
                {/* <button class="w3-button w3-blue">Download Image</button>
                <button class="w3-button w3-green">Copy link to clipboard</button> */}
                <a id="post27" href="http://desudesudesu.io/#post27">Post 27</a>
                {/* <div class="w3-row" style={{width: '100%'}}>
                    <div class="w3-col s3">
                        <a id="post27" href="http://desudesudesu.io/#post27">Post 27</a>
                    </div>
                    <div class="w3-col s3">
                        <a href="https://i.imgur.com/QAcmmPX.png" download>
                            <button class="w3-button w3-blue">Download Image</button>
                        </a>
                    </div>
                    <div class="w3-col s3">
                        <a href="https://i.imgur.com/QAcmmPX.png" download>
                            <button class="w3-button w3-green">Copy link to clipboard</button>
                        </a>
                    </div>
                    <div class="w3-col s6"></div>
                </div> */}
                {/* <a id="post27" href="http://desudesudesu.io/#post27">Post 27</a> */}
                <img src="https://i.imgur.com/QAcmmPX.png" alt="google does it again" style={{maxWidth: '100%'}}/>

                <br/>
                <br/>
                <a id="post26" href="http://desudesudesu.io/#post26">Post 26</a>
                <img src="https://media.giphy.com/media/Y0hrTpOmfRNKGNjxgf/giphy.gif" alt="rich fairbanks" style={{maxWidth: '100%'}}/>

                <br/>
                <br/>
                <a id="post25" href="http://desudesudesu.io/#post25">Post 25</a>
                <img src="https://i.imgur.com/nDPti6G.jpg" alt="spicy memes" style={{maxWidth: '100%'}}/>

                <br/>
                <br/>
                <a id="post24" href="http://desudesudesu.io/#post24">Post 24</a>
                <img src="https://i.imgur.com/lQLTtMg.png" alt="GTFO" style={{maxWidth: '100%'}}/>

        {/*  */}

                <br/>
                <br/>
                {/* http://localhost:3000/ */}
                {/* <a id="post21" href="http://localhost:3000/#post21">Post 21</a> */}
                <a id="post23" href="http://desudesudesu.io/#post23">Post 23</a>
                <img src="https://i.imgur.com/aujOVi9.png" alt="lorax" style={{maxWidth: '100%'}}/>

                <br/>
                
                <br/>
                {/* <a id="post20" href="http://localhost:3000/#post20">Post 20</a> */}
                <a id="post22" href="http://desudesudesu.io/#post22">Post 22</a>
                <img src="https://i.imgur.com/a6yhPML.png" alt="mentorship" style={{maxWidth: '100%'}}/>

                <br/>
                <br/>
                <a id="post21" href="http://desudesudesu.io/#post21">Post 21</a>
                <img src="https://i.imgur.com/Nk2WHwg.png" alt="nessie" style={{maxWidth: '100%'}}/>

                <br/>
                <br/>
                <a id="post20" href="http://desudesudesu.io/#post20">Post 20</a>
                <img src="https://i.imgur.com/qCZsVvU.png" alt="great corporate culture" style={{maxWidth: '100%'}}/>

        {/*  */}

              <br/>
              <br/>
              <a id="post19" href="http://desudesudesu.io/#post19">Post 19</a>
              <img src="https://i.imgur.com/0x892wU.png" alt="google" style={{maxWidth: '100%'}}/>

              <br/>
              <br/>
              <a id="post18" href="http://desudesudesu.io/#post18">Post 18</a>
              <img src="https://i.imgur.com/LC0TZgW.png" alt="blind posts" style={{maxWidth: '100%'}}/>

              <br/>
              <br/>
              <a id="post17" href="http://desudesudesu.io/#post17">Post 17</a>
              <img src="https://i.imgur.com/8di4UfV.png" alt="HR shill" style={{maxWidth: '100%'}}/>

              <br/>
              <br/>
              <a id="post16" href="http://desudesudesu.io/#post16">Post 16</a>
              <img src="https://i.imgur.com/QGP1d1J.jpg" alt="tech company chart" style={{maxWidth: '100%'}}/>

              <br/>
              <br/>
              <a id="post15" href="http://desudesudesu.io/#post15">Post 15</a>
              <img src="https://i.imgur.com/FHyM3ne.png" alt="tech company wolf" style={{maxWidth: '100%'}}/>

              <br/>
              <br/>
              <a id="post14" href="http://desudesudesu.io/#post14">Post 14</a>
              <img src="https://i.imgur.com/qspKwlQ.jpg" alt="scrum masters akko" style={{maxWidth: '100%'}}/>

              <br/>
              <br/>
              <a id="post13" href="http://desudesudesu.io/#post13">Post 13</a>
              <img src="https://i.imgur.com/WvcknXu.jpg" alt="scrum masters cosplay" style={{maxWidth: '100%'}}/>

              <br/>
              <br/>
              <a id="post12" href="http://desudesudesu.io/#post12">Post 12</a>
              <img src="https://i.imgur.com/QCVvjAx.jpg" alt="shit tech v card tech" style={{maxWidth: '100%'}}/>

              <br/>
              <br/>
              <a id="post11" href="http://desudesudesu.io/#post11">Post 11</a>
              <img src="https://i.imgur.com/nSZBVGM.png" alt="winnie the pooh" style={{maxWidth: '100%'}}/>

              <br/>
              <br/>
              <a id="post10" href="http://desudesudesu.io/#post10">Post 10</a>
              <img src="https://i.imgur.com/gViB42D.png" alt="VPs recruiting" style={{maxWidth: '100%'}}/>

              <br/>
              <br/>
              <a id="post9" href="http://desudesudesu.io/#post9">Post 9</a>
              <img src="https://i.imgur.com/AgS0NYo.jpg" alt="drake on nepotism in stack ranking" style={{maxWidth: '100%'}}/>

              <br/>
              <br/>
              <a id="post8" href="http://desudesudesu.io/#post8">Post 8</a>
              <img src="https://i.imgur.com/MtfmiMz.png" alt="career growth" style={{maxWidth: '100%'}}/>

              <br/>
              <br/>
              <a id="post7" href="http://desudesudesu.io/#post7">Post 7</a>
              <img src="https://i.imgur.com/UdiBy5E.png" alt="Year 1 v Year 2" style={{maxWidth: '100%'}}/>

              <br/>
              <br/>
              <a id="post6" href="http://desudesudesu.io/#post6">Post 6</a>
              <img src="https://i.imgur.com/Xo1s7HY.png" alt="upper management pouring kool-aid" style={{maxWidth: '100%'}}/>

              <br/>
              <br/>
              <a id="post5" href="http://desudesudesu.io/#post5">Post 5</a>
              <img src="https://i.imgur.com/G1U5J94.png" alt="ML" style={{maxWidth: '100%'}}/>

              <br/>
              <br/>
              <a id="post4" href="http://desudesudesu.io/#post4">Post 4</a>
              <img src="https://i.imgur.com/d3Cfd1v.png" alt="brain child" style={{maxWidth: '100%'}}/>

              <br/>
              <br/>
              <a id="post3" href="http://desudesudesu.io/#post3">Post 3</a>
              <img src="https://i.imgur.com/RGCItBG.png" alt="mailbox" style={{maxWidth: '100%'}}/>

              <br/>
              <br/>
              <a id="post2" href="http://desudesudesu.io/#post2">Post 2</a>
              <img src="https://i.imgur.com/SntW6eE.png" alt="original with van going off a cliff" style={{maxWidth: '100%'}}/>

              <br/>
              <br/>
              <a id="post1" href="http://desudesudesu.io/#post1">Post 1</a>
              <img src="https://i.imgur.com/6oJXVQ8.png" alt="original with vending machine" style={{maxWidth: '100%'}}/>

              <br/>
              <br/>
            </div>



            {/* https://i.imgur.com/RGCItBG.png */}

            {/* <HeaderComponent />
            <MainComponent /> */}
        </div>
    );
}

export default App;
