import React from 'react';

export class ImagePostComponent extends React.Component {
    render() {
      return (
        <div>
          Hello
        </div>
      );
    }
  }