import React from 'react';

export class HeaderComponent extends React.Component {
    render() {
      return (
        <div id="navDemo" className="w3-bar-block w3-black w3-top" style={{  }}>
            {/* <a href="#band" className="w3-bar-item w3-button w3-padding-large">Welcome to D</a> */}
            
            {/* <a href="#band" className="w3-bar-item w3-button w3-padding-large">WELCOME TO DESU'S REALM, PUSSY.</a> */}
            {/* <span class="w3-left w3-padding">SOME NAME</span> */}
            {/* <span className="w3-bar-item w3-padding-large">WELCOME TO DESU'S REALM - A GLORIOUS TRIBUTE TO DESU'S HOLY CRUSADE AGAINST CAPITAL ONE</span> */}
            <span className="w3-bar-item w3-padding-large">WELCOME TO DESU'S REALM</span>
            {/* WELCOME TO DESU'S REALM, PUSSY. */}

            {/* <a href="#band" class="w3-bar-item w3-button w3-padding-large" onclick="myFunction()">BAND</a>
            <a href="#tour" class="w3-bar-item w3-button w3-padding-large" onclick="myFunction()">TOUR</a>
            <a href="#contact" class="w3-bar-item w3-button w3-padding-large" onclick="myFunction()">CONTACT</a>
            <a href="#" class="w3-bar-item w3-button w3-padding-large" onclick="myFunction()">MERCH</a> */}
        </div>
      );
    }
  }